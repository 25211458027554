import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "./Grid.scss";

class Grid extends PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const { children } = this.props;
        return <div className="grid">{children}</div>;
    }
}

Grid.propTypes = {
    children: PropTypes.node.isRequired
};

export default Grid;
